function buildDatePicker(element,showDay){
    if (showDay === undefined) {
        showDay = true;
    }
    var options = {};
    if (showDay){
      options = {
          language: "pt-BR",
          format: 'dd/mm/yyyy',
          autoclose: true
      };
    } else {
       options = {
          language: "pt-BR",
          format: "mm/yyyy",
          viewMode: "months",
          minViewMode: "months",
          autoclose: true
      };
    }
    $(element).datepicker(options);
};

function buildTimePicker(element) {
 $(element).timepicker({
        showInputs: true,
        showMeridian: false,
        minuteStep: 5
    });
}

function enableDatePicker(){
   $('.showdatepicker').each(function(){
      buildDatePicker(this,  $(this).data('showday'));
   })
}

function enableTimePicker(){
  $('.showtimepicker').each(function(){
    buildTimePicker(this);
  })
}

function enableMaskedInput(element){
  $('.phone').mask('(99) 9999-99999').focusout(function (event) {
            var target, phone, element;
            target = (event.currentTarget) ? event.currentTarget : event.srcElement;
            phone = target.value.replace(/\D/g, '');
            element = $(target);
            element.unmask();
            if(phone.length > 10) {
                element.mask("(99) 99999-999?9");
            } else {
                element.mask("(99) 9999-9999?9");
            }
          });
  $(".cpf").mask("999.999.999-99");
  $(".cnpj").mask("99.999.999/9999-99");
  $(".cep").mask("99999-999");
  // $('.money').mask('000.000.000.000.000,00', {reverse: true});
  $('.money').mask("#.##0,00", {reverse: true});
}

function enableMaskMoney() {
  $('.money').each(function(){
    var sanited_value = $(this).val().replace(/\./g, ',');
    $(this).val(sanited_value);
    $(this).maskMoney({decimal: ',', thousands: '.', precision: 2, prefix: 'R$ ',allowZero: true});
  })
}

function enableEstadoCidade(){
    var cidade_field;
    if (document.getElementById("cidade_id")){
        cidade_field = $("#cidade_id");
    }else if (document.getElementById("endereco_cidade_id")) {
        cidade_field = $("#endereco_cidade_id");
    }
    $("#estado_id").on("change select2-clearing",function(){
      var estado_id = $(this).val();
      if (estado_id == '') {

      }else {
        cidade_field.val('');
        cidade_field.data("ajax-url","/admin/cidades.json?estado_id="+estado_id);
      }
      makeAutoComplete(0,cidade_field);
    })
}

function enableCidadeCartorio(){
    var cartorio_field;
    if (document.getElementById("cartorio_id")){
        cartorio_field = $("#cartorio_id");
    }
    $("#cidade_id").on("change select2-clearing",function(){
      var cidade_id = $(this).val();
      if (cidade_id == '') {
      }else {
        cartorio_field.val('');
        cartorio_field.data("ajax-url","/admin/cartorios.json?cidade_id="+cidade_id);
      }
      makeAutoComplete(0,cartorio_field);
    })
}

function growAlert(type,message){
    $.niftyNoty({
      type: type,
      container : 'floating',
      html : message,
      timer : true ? 10000 : 0
    });
}

$('#default-modal').on('show.bs.modal', function (e) {
  ready();
})


// ORDENAÇÃO TABLES
function enableSort(){
  $('.dataTables_wrapper').each(function(){
    buildSort(this);
  })
};
function buildSort(element){
  $(element).find('.order-by').on('click', function(e){
    e.preventDefault();
    var order_by = $(this).data('order-by')
    var sort = $(this).data('sort')
    if (sort === 'desc') {
      sort = 'asc'
    } else if (sort ==='asc'){
      sort = 'desc'
    } else {
      sort = 'asc'
    }
    console.log(sort);
    $(element).find("input[name='order_by']").val(order_by);
    $(element).find("input[name='sort']").val(sort);
    $(element).find('.sort').removeClass(function (index, css) {
      return (css.match (/(^|\s)fa-sort-\S+/g) || []).join(' ');
    });
    $(element).find('.sort').addClass('fa-sort');
    $(this).data('sort', sort);
    console.log($(this).data('sort'));
    $(this).find('.sort').removeClass('fa-sort');
    $(this).find('.sort').addClass('fa-sort-' + sort);
    var form = $(element).find('.search-local')[0];
    Rails.fire(form, 'submit');
  })

  $(element).find('.per').on('change', function(){
    var form = $(element).find('.search-local')[0];
    Rails.fire(form, 'submit');
    // sleep 3;
  });
};

function buildLocalBarSelect(value){
  if (value['multiple'] == true){
    $('#field-query').html("<input type='hidden' name='query[]' id='"+value['id']+"'\
         class='form-control autocompletable' data-auto-complete='true'\
          data-ajax-url='"+value['source']+"' data-hidden-field-id='"+value['id']+"'\
           data-multiple='true' size=40'>")
  } else {
    $('#field-query').html("<input type='select' name='query' id='"+value['id']+"'\
         class='form-control autocompletable' data-auto-complete='true'\
          data-ajax-url='"+value['source']+"' data-hidden-field-id='"+value['id']+"'\
           data-multiple='false' size=40'>")
  }
  enableAutoComplete();
}

function buildNewLocalBarSelect(value){
  if (value['multiple'] == true){
    $('#field-query').html("<select name='query[]' id='"+value['id']+"'\
         class='form-control autocompletable' data-auto-complete='true'\
          data-ajax-url='"+value['source']+"' data-hidden-field-id='"+value['id']+"'\
           data-multiple='true' size=40'></select>")
  } else {
    $('#field-query').html("<select name='query' id='"+value['id']+"'\
         class='form-control autocompletable' style='min-width: 200px;' data-auto-complete='true'\
          data-ajax-url='"+value['source']+"' data-hidden-field-id='"+value['id']+"'\
           data-multiple='false' size=40'></select>")
  }
  enableAutoComplete();
}

function buildLocalBar(options=[]) {
  var select = false;
  $.each(options, function(i, value){
    if ($('#field').val() == value['id']) {
      select = true;
      if (value['sl2'] == 'new') {
        buildNewLocalBarSelect(value);
      } else {
       buildLocalBarSelect(value);
      }
    }
  })
  if(select == false) {
    $('#field-query').html("<input type='text' name='query' class='form-control'>")
  }
}

function searchLocalBarChange(options=[]) {
  buildLocalBar(options);
  $('#field').on('change', function(){
    buildLocalBar(options);
  })
}


function enableJsFlash(){
  $('.js-flash-message').each(function(){
    growAlert($(this).data('grow-class'), $(this).data('grow-msg'));
  })
}


function enableSetMax(){
  $('.distribuicao_de_ato_set_max').on('change', function(){
    var input = $("#"+ $(this).data('reference'));
    if ($(this).is(':checked')) {
    console.log(input);
      $(input).val($(input).data('max'));
    } else {
      $(input).val('');
    }
  })
}


function enableRemoteDownload(){
  $('.remote_download').on('click', function (event) {
      event.preventDefault();
      e = $(this);
      var oReq = new XMLHttpRequest();
      oReq.open("GET", $(e).data('source'), true);
      oReq.responseType = "arraybuffer";

      oReq.onload = function(oEvent) {
        var arrayBuffer = oReq.response;
        // var byteArray = new Uint8Array(arrayBuffer);

        var blob = new Blob([arrayBuffer], {type: $(e).data('format')});
        var url = URL.createObjectURL(blob);

        var a = document.createElement('a');
        a.href = url;
        a.download = 'selos_'+ $(e).data('id') + '.' + $(e).data('format-name');
        a.dispatchEvent(new MouseEvent(`click`));
        $(e).hide();

      };
      oReq.send();

  });
}

    // DEFAULT RANGE SLIDER
    // =================================================================
    // Require noUiSlider
    // http://refreshless.com/nouislider/
    // =================================================================
function buildSlider(e){
  console.log(e);
    var rs_def = e;
    var rs_def_value = $(e).data('value-id');
    var rs_hidden_value = $(e).data('hidden-id');
    var min = $(e).data('min');
    var max = $(e).data('max');

    noUiSlider.create(rs_def,{
        start   : [ max ],
        step: 1,
        range   : {
            'min': [  min ],
            'max': [ max ]
        }
    });

    rs_def.noUiSlider.on('update', function( values, handle ) {
      var value = Math.round(values[handle]);
      console.log(value);
        $('#'+rs_def_value).html(value);
        $('#'+rs_hidden_value).val(value);
    });
}

function enableSlider(){
  $('.range-slider').each(function(){
    buildSlider(this);
  });
};

function updateNotifications(){
  $.get('/notifications/unread.js',function(data){});
};

function enableNotificationRead(){
  $('.notification-index-list').on('click', function(){
    if ($(this).data('readed') == false){
      $.get('/notifications/'+ $(this).data('notification-id') + '/mark_as_read.js',function(data){});
    }
  })
}

// DINAMIC SELECT COMBOS
 // =================================================================
function buildDinamicSelect(element){
  var parent_field = $(element).data('parent-field');
  var parent_id = $(element).data('parent-id');
  $(parent_id).on('change',function(i){
    var path = ''
    path =  $(element).data('ajax-url').replace(/\?.+/,'');
    $(element).val('');
    var value = $(this).val();
    if (value === 'all'){
      var val = $($(this).data('parent-id')).val();
      path = path+'?parent_field='+$(this).data('parent-field')+'&parent_id='+val;
    }else{
      path = path+'?parent_field='+parent_field+'&parent_id='+value;
    }
    $(element).data('ajax-url', path);
    if (value != '' || value != 'all') {
      $(element).attr('disabled', false);
    } else {
      $(element).attr('disabled', true);
      $(element).val('');
      $("[data-parent-id='#" + $(element).attr('id') +"']").each(function(){
        $(this).attr('disabled', true);
        $(this).val('');
      })
    }
    makeAutoComplete(i,element);
  })
};

function updateChildren(parent) {
  var parentValue = $(parent).val();
  var searchCollumn = $(parent).data('search-collumn');
  var childrenField = $($(parent).data('children-id'));
  childrenField.val('');
  var url = $(parent).data('source-path');
  childrenField.data("ajax-url", url+'?'+searchCollumn+'='+parentValue);
  childrenField.removeAttr('disabled')
  makeAutoComplete(0,childrenField);
}

jQuery(".cpfcnpj").keyup(function () {
    mascaraMutuario(this,cpfCnpj);
  });

  function mascaraMutuario(o,f){
    v_obj=o;
    v_fun=f;
    setTimeout('execmascara()',1);
  }

  function execmascara(){
    v_obj.value=v_fun(v_obj.value);
  }

  function cpfCnpj(v){
    v=v.replace(/\D/g,"");

    if (v.length+1 <= 14) { //CPF

        v=v.replace(/(\d{3})(\d)/,"$1.$2");
        v=v.replace(/(\d{3})(\d)/,"$1.$2");
        v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2");

    } else { //CNPJ
        v=v.replace(/^(\d{2})(\d)/,"$1.$2");
        v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3");
        v=v.replace(/\.(\d{3})(\d)/,".$1/$2");
        v=v.replace(/(\d{4})(\d)/,"$1-$2");
    }
    return v.substr(0,18);
  }

function enableDinamicSelect(){
  $("[rel='children_field']").each(function(){
    buildDinamicSelect(this);
  })
}

function PrintElem(elem) {

    var mywindow = window.open('', 'PRINT', 'width=29cm');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + document.title  + '</h1>');
    mywindow.document.write(document.getElementById(elem).innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
}

function changeRestituicao(v){
  if (v === 'false') {
    document.getElementById('declaracao_de_custeio_restituicao').removeAttribute('disabled');

  } else {
    document.getElementById('declaracao_de_custeio_restituicao').disabled = true;
  }
}

function brlToFloat(value) {
  return value.replace(/[^0-9\,]+/g,"").replace(/\,/,'.');
}

function updateReceitas(){
  const restituicaoInput = document.getElementById('declaracao_de_custeio_restituicao');
  const receitaInput = document.getElementById('receita-do-mes');
  const receita = new Number(brlToFloat(receitaInput.value));
  const result = document.getElementById('total-receita');
  let restituicao = new Number(brlToFloat(restituicaoInput.value));
  let totalReceita = (receita + restituicao);

  result.textContent = (totalReceita).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  restituicaoInput.addEventListener('change', (event) => {
    let restituicao = new Number(brlToFloat(event.target.value));
    let totalReceita = (receita + restituicao);


    result.textContent = (totalReceita).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

  })
}

function fic_ausencia() {
  let ausencia_input = document.getElementById('fic_ausencia');
  let valor_input = document.getElementById('fic_valor');
  let anexo_input = document.getElementById('fic_anexo');
  console.log('ausencia')

    if ( ausencia_input.checked) {
      anexo_input.disabled = true
      valor_input.disabled = true
      valor_input.value = 0
    } else {
      anexo_input.disabled = false
      valor_input.disabled = false
    }

}

function funcionarioAtribuicaoDocs(element) {
  let selectElem = element;
  let haiaDiv = selectElem.parentNode.parentNode.parentNode.querySelector('.haia-docs')
  if (selectElem.value == 'apostilante_haia') {
    haiaDiv.classList.remove('hidden');
  } else {
    if (haiaDiv.classList.contains('hidden')) {
      return;
    } else {
      haiaDiv.classList.add('hidden');
    }
  }
}

function forceNumeric(){
  var input = $('#codigo_selo')
  let text = input.val();
  console.log(text);
  input.val(text.replace(/[^\d]+/g,''));
}

export {
  enableMaskMoney, enableRemoteDownload, enableSetMax,  enableEstadoCidade,
  growAlert, enableDatePicker, enableTimePicker, enableMaskedInput, enableJsFlash, enableNotificationRead, enableSort,
  updateNotifications, searchLocalBarChange, enableDinamicSelect, updateChildren, PrintElem, changeRestituicao, updateReceitas,
  fic_ausencia, funcionarioAtribuicaoDocs, forceNumeric }
